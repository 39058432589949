<template>
  <div class="animated fadeIn">
    <h4>Примеры файлов</h4>
    <form
      @submit.prevent="onSubmit"
    >
      <b-row>
        <b-col
          v-for="(exampleDocument, index) in exampleDocuments"
          :key="exampleDocument.code"
          md="4"
        >
          <b-form-group
            :label="exampleDocument.name"
          >
            <b-form-file
              :id="'exampledocument'+index"
              v-model="exampleDocument.document"
              v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
              name="passport-main-page"
              accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
              placeholder="Файл не выбран"
              browse-text="Выбрать"
              drop-placeholder="Перетащите файл сюда"
              @input="uploadFile(index)"
            />
            <div
              v-if="exampleDocument.document && exampleDocument.document.file"
              class="mt-2"
            >
              Загруженный файл:
              <a
                v-if="exampleDocument.document.file"
                :href="'/files/' + exampleDocument.document.file ? exampleDocument.document.file.guid : ''"
                target="_blank"
                @click.prevent="downloadFile(exampleDocument.document.file)"
              >{{ exampleDocument.document.file.originalName }}</a>
            </div>
          </b-form-group>
        </b-col>
        <div class="fixed-buttons">
          <b-button
            type="button"
            variant="secondary"
            class="mr-2"
            :class="{'loading' : loading}"
            @click="$router.go(-1)"
          >
            Назад
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="loading"
            :class="{'loading' : loading}"
          >
            Сохранить
          </b-button>
        </div>
      </b-row>
    </form>
  </div>
</template>

<script>
import {updateExampleDocument, documentCreate, downloadFile, getExampleDocuments} from '@/services/api';
import notifications from '@/components/mixins/notifications';

export default {
  name: 'ExampleDocumentsConfiguration',
  mixins: [notifications],
  data() {
    return {
      exampleDocuments: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const result = await getExampleDocuments({limit: 999});
    if (result.status === 200) {
      console.log(result);
      this.exampleDocuments = result.data.items;
    }
    this.loading = false;
  },
  methods: {
    downloadFile,
    uploadFile(key) {
      this.loading = true;
      const file = this.exampleDocuments[key].document;
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          this.loading = true;
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.uploadDocument(key, upload);
        });
      }
      this.loading = false;
    },
    async uploadDocument(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.exampleDocuments[key].document = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onSubmit() {
      this.loading = true;
      this.exampleDocuments.forEach((exampleDocument)=>{
        this.updateExampleDocument(exampleDocument.code, exampleDocument);
      });
      this.showSuccess('Примеры обновлены');
      this.loading = false;
    },
    async updateExampleDocument(code, exampleDocument){
      await updateExampleDocument(exampleDocument.code, exampleDocument);
    },
  },
};
</script>

<style scoped>

</style>
